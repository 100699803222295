import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const injectGA = () => {
      if (typeof window == 'undefined') {
        return
      }
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag('js', new Date())

      gtag('config', 'G-577MST7J0C')
    }
    const postLinks = posts.map((post) => {
      let eserler = post.node.frontmatter.eserler
      let first = ''
      let i = 0
      while (first === '') {
        let eser = eserler[i]
        if (!eser.video) {
          first = eser.eser
        }
        i++
        if (i === 4) {
          first = 'nope'
        }
      }
      return (
        <Link
          className="etiketEser"
          to={post.node.fields.slug}
          key={post.node.frontmatter.isim}
        >
          {first !== 'nope' ? (
            <div
              style={{
                backgroundImage: `url("` + `${first}` + `")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              className="etiketYazi open-sans-bold"
            >
              <div className="etiketYazisi open-sans-bold">
                {' '}
                {post.node.frontmatter.isim}{' '}
              </div>
            </div>
          ) : (
            <p className="etiketYazi open-sans-bold">
              {post.node.frontmatter.isim}
            </p>
          )}
        </Link>
      )
    })
    const etiketSade = this.props.pageContext.etiketSade
    // const totalCount = this.props.data.allMarkdownRemark.totalCount
    // const tagHeader = `${totalCount} artvizit “${etiket}” ile etiketlendi`
    const tagHeader = `${etiketSade} temalı artvizit seçkisi`
    return (
      <Layout>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-577MST7J0C"
          />
          {/* <script type='text/javascript' dangerouslySetInnerHTML={{ __html: gaScript }} /> */}
          <script>{injectGA()}</script>
        </Helmet>
        <section className="section">
          <Helmet title={`${etiketSade} | Artvizit`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
                <ul className="taglist">{postLinks}</ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($etiket: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          etiketler: { elemMatch: { benzer: { regex: $etiket } } }
        }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            isim
            etiketler {
              etiket
              benzer
            }
            eserler {
              eser
              video
              kunye
              aciklama
            }
          }
        }
      }
    }
  }
`
